import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { AccountObj, AnAccount, AUserPermission } from "api/account";
import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";
import { LoginObj } from "api/credentialsAPI";
import { APermissionList } from "api/moduleAPI";
import {
  ADashboardPermission,
  AManagementPermission,
  AOtherPermission,
  PermissionReqObj,
} from "api/permissionAPI";
import { convertToEncodedURL, jsonToFormData } from "assets/usefulFunctions";

export const userStoreAPI = createApi({
  reducerPath: "userStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/user`,
    prepareHeaders: (headers, { endpoint }) => {
      const excludedEndpoints = ["changePasswordWithToken"];
      /**
       * @description selain endpoint yang ada di value excludedEndpoints, tidak
       * akan melakukan set headers.
       */
      if (!excludedEndpoints.includes(endpoint)) {
        let token = CookieMonster.loadCookie("jwt");
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  tagTypes: ["UserList", "User", "Permission"],
  endpoints: (builder) => {
    return {
      letUserLogin: builder.mutation({
        query: (obj: LoginObj) => {
          return {
            url: "/login",
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["UserList"],
      }),
      getUser: builder.query<AnAccount[], Partial<AccountObj>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AnAccount[]>) =>
          response.data,
        providesTags: ["UserList"],
      }),
      getMyPermission: builder.query<
        {
          permissions: APermissionList;
        },
        {}
      >({
        query: () => ({
          url: "/permissions",
        }),
        transformResponse: (response: BackendDataShape<AUserPermission[]>) => {
          const {
            permissions: { dashboard, management, other },
          } = response.data[0] ?? { permissions: {} };

          const permissionList: APermissionList = {
            dashboard: [],
            management: [],
            other: [],
          };

          Object.keys(dashboard).forEach((k) => {
            let key = k as keyof ADashboardPermission;
            let val = dashboard[key];
            if (val) {
              permissionList.dashboard.push(key);
            }
          });

          Object.keys(management).forEach((k) => {
            let key = k as keyof AManagementPermission;
            let val = management[key];
            if (val) {
              permissionList.management.push(key);
            }
          });

          Object.keys(other).forEach((k) => {
            let key = k as keyof AOtherPermission;
            let val = other[key];
            if (val) {
              permissionList.other.push(key);
            }
          });

          return {
            permissions: permissionList,
          };
        },

        providesTags: ["Permission", "UserList"],
      }),

      createUser: builder.mutation<
        BackendDataShape<AnAccount>,
        Partial<AccountObj>
      >({
        query: (obj) => {
          return {
            url: "/register",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["UserList"],
      }),

      updateUser: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<AccountObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["UserList"],
      }),

      deleteUser: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["UserList"],
      }),
      resertpassword: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/resetpassword/${id}`,
            method: "PATCH",
          };
        },
        invalidatesTags: ["UserList"],
      }),
      changePassword: builder.mutation<
        BackendDataShape<string>,
        {
          id: string;
          newPassword: string;
        }
      >({
        query: (obj) => {
          return {
            url: `/change-password/${obj.id}`,
            method: "PATCH",
            body: {
              newPassword: obj.newPassword,
            },
          };
        },
        invalidatesTags: ["User"],
      }),
      changePasswordWithToken: builder.mutation<
        BackendDataShape<string>,
        {
          token: string;
          newPassword: string;
        }
      >({
        query: ({ token, newPassword }) => {
          return {
            url: `/change-password`,
            method: "PATCH",
            body: {
              password: newPassword,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        invalidatesTags: ["User"],
      }),
      savePermissions: builder.mutation<
        BackendDataShape<string>,
        PermissionReqObj
      >({
        query: (obj) => {
          return {
            url: `/permissions/save`,
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["UserList"],
      }),

      resendVerificationEmail: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
        }
      >({
        query: (id) => {
          return {
            url: `/verify/email`,
            body: id,
            method: "POST",
          };
        },
        invalidatesTags: ["UserList"],
      }),
      uploadProfileUserImage: builder.mutation<
        BackendDataShape<string>,
        FormData
      >({
        query: (obj) => {
          return {
            url: `/picture/upload`,
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["User"],
      }),
      generateOtp: builder.mutation<
        BackendDataShape<string>,
        { password: string }
      >({
        query: (obj) => {
          return {
            url: `/otp`,
            method: "PATCH",
            body: obj,
          };
        },
        // invalidatesTags: ["User"],
      }),
      validateOtp: builder.mutation<
        BackendDataShape<{ token: string }>,
        { otp: string }
      >({
        query: (obj) => {
          return {
            url: `/otp/validate`,
            method: "PATCH",
            body: obj,
          };
        },
        // invalidatesTags: ["User"],
      }),

      restoreUser: builder.mutation<BackendDataShape<object>, { file: File }>({
        query: ({ ...rest }) => {
          return {
            url: "/restore",
            body: jsonToFormData(rest),
            method: "POST",
          };
        },
        invalidatesTags: ["UserList"],
      }),
    };
  },
});

export const {
  useGetUserQuery,
  useGetMyPermissionQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useResertpasswordMutation,
  useSavePermissionsMutation,
  useResendVerificationEmailMutation,
  useChangePasswordMutation,
  useChangePasswordWithTokenMutation,
  useUploadProfileUserImageMutation,
  useGenerateOtpMutation,
  useValidateOtpMutation,
  useRestoreUserMutation,
  util: { resetApiState: resetUserStoreAPI },
} = userStoreAPI;
