import React, { lazy } from "react";
import { IconBaseProps } from "react-icons";
import { GoChecklist } from "react-icons/go";
import {
  MdDashboard,
  MdOutlineAccountCircle,
  MdOutlineBackup,
  MdOutlineDescription,
  MdOutlineLogout,
  MdViewList,
  MdWarning,
} from "react-icons/md";
import { RiAccountBoxFill } from "react-icons/ri";
import { HashRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";

import { KnownUsertype, KnownUsertypeName } from "api/credentialsAPI/usertype";

import { COLORS } from "assets/theme";

import LoaderPage from "components/Atoms/Loader/LoaderPage";
import AuthHOC from "components/Auth/AuthHOC";
import UserGate from "components/Auth/UserGate";
import { SidebarItemProps } from "components/Sidebar/_components/SidebarItem";
import { StyledToastContainerPuspenerbal } from "components/Toast/StyledToastContainerPuspenerbal";

import {
  ListOfRoutes,
  SisfologRoutes,
  SisfoOpsRoutes,
  SisfopersRoutes,
} from "config/nav";

import Layout from "layout/Layout";

import NoMatch from "pages/NoMatch";
import Redirector from "pages/Redirector";
const ChangePwdPage = lazy(
  () => import("pages/SisfopersPages/AccountPage/ChangePwdPage"),
);
const GetOtpPage = lazy(
  () => import("pages/SisfopersPages/AccountPage/GetOtpPage"),
);
const PersonelFilePage = lazy(
  () => import("pages/SisfopersPages/PersonelFilePage/PersonelFilePage"),
);
const LoginPage = lazy(() => import("pages/LoginPage"));
const HomePage = lazy(() => import("pages/HomePage"));
const PersonelDashboardPage = lazy(
  () => import("pages/SisfopersPages/DashboardPage/PersonelDashboard"),
);

const CaseDashboardPage = lazy(
  () => import("pages/SisfopersPages/DashboardPage/LawDashboard"),
);
const OverviewDashboardPage = lazy(
  () => import("pages/SisfopersPages/DashboardPage/OverviewDashboardPage"),
);

const GarjesDashboardPage = lazy(
  () => import("pages/SisfopersPages/DashboardPage/HealthyDashboard"),
);

const ApprovalPersonelPage = lazy(
  () =>
    import(
      "pages/SisfopersPages/ManagementPages/ApprovalPage/ApprovalPersonelPage"
    ),
);
const NotificationPage = lazy(
  () => import("pages/SisfopersPages/NotificationPage/NotificationPage"),
);
const ActivePersonelManagementPage = lazy(
  () =>
    import(
      "pages/SisfopersPages/ManagementPages/ActivePenerbalPage/ActivePenerbalPage"
    ),
);
const PersonilViewPage = lazy(
  () =>
    import(
      "pages/SisfopersPages/ManagementPages/ActivePenerbalPage/PersonilViewPage"
    ),
);
const AccountViewPage = lazy(
  () =>
    import("pages/SisfopersPages/ManagementPages/AdminPage/AccountViewPage"),
);
const SisfoPersLogoutPage = lazy(() => import("pages/SisfopersPages/Logout"));
const RankManagementPage = lazy(
  () =>
    import(
      "pages/SisfopersPages/ManagementPages/DatapersPage/RankManagementPage"
    ),
);
const PositionManagementPage = lazy(
  () =>
    import(
      "pages/SisfopersPages/ManagementPages/DatapersPage/PositionManagementPage"
    ),
);
const SatkerManagementPage = lazy(
  () =>
    import(
      "pages/SisfopersPages/ManagementPages/DatapersPage/WorkunitManagementPage"
    ),
);
const MasterDataEducationManagementPage = lazy(
  () =>
    import(
      "pages/SisfopersPages/ManagementPages/DatapersPage/PendidikanManagementPage"
    ),
);
const MasterDataKorpsManagementPage = lazy(
  () =>
    import(
      "pages/SisfopersPages/ManagementPages/DatapersPage/KorpsManagementPage"
    ),
);
const WorkflowPage = lazy(
  () => import("pages/SisfopersPages/ManagementPages/WorkflowPage"),
);

const ReportPage = lazy(
  () => import("pages/SisfopersPages/ReportPage/ReportPage"),
);

const CaseManagementPage = lazy(
  () =>
    import("pages/SisfopersPages/ManagementPages/CasePage/CaseManagementPage"),
);
const MasterDataPositionProfessionManagementPage = lazy(
  () =>
    import(
      "pages/SisfopersPages/ManagementPages/DatapersPage/MasterDataPositionProfessionManagementPage"
    ),
);
const MasterDataQualificationManagementPage = lazy(
  () =>
    import(
      "pages/SisfopersPages/ManagementPages/DatapersPage/MasterDataQualificationManagementPage"
    ),
);
const MasterDataKeahlianManagementPage = lazy(
  () =>
    import(
      "pages/SisfopersPages/ManagementPages/DatapersPage/MasterDataKeahlianManagementPage"
    ),
);
const RecomendationPositionManagementPage = lazy(
  () =>
    import(
      "pages/SisfopersPages/ManagementPages/DalpersPage/RecomendationPositionManagementPage"
    ),
);
const EligibleRankPage = lazy(
  () =>
    import("pages/SisfopersPages/ManagementPages/DalpersPage/EligibleRankPage"),
);
const RetirementRecommendationPage = lazy(
  () =>
    import(
      "pages/SisfopersPages/ManagementPages/WatpersPage/RetirementRecommendationPage"
    ),
);
const FlightQualificationPage = lazy(
  () =>
    import(
      "pages/SisfopersPages/ManagementPages/ProfesiPage/FlightQualificationPage"
    ),
);

const BackupPage = lazy(
  () => import("pages/SisfopersPages/BackupRestorePage/BackupPage"),
);

const RestorePage = lazy(
  () => import("pages/SisfopersPages/BackupRestorePage/RestorePage"),
);

const AlertPage = lazy(() => import("pages/SisfopersPages/AlertPage"));
const ImportPage = lazy(() => import("pages/SisfopersPages/ImportPage"));

const sisfopersRoutes = new SisfopersRoutes();
const sisfologRoutes = new SisfologRoutes();
const sisfoopsRoutes = new SisfoOpsRoutes();

const allowedForApprovalPage: (KnownUsertype | KnownUsertypeName)[] = [
  "kasubsiJasrek", // Data Umum Personel
  "kasiWatpers", // Data Garjas
  // "kasiMindik"

  "kasubsiDatapers", // Master Data

  "kasiMindik", // Data Pendidikan

  "kasubsiDalpersmil", // Data Dalpers A
  "kasiDalpersmil", // Data Dalpers A
  "kasiDalperssip", // Data Dalpers A

  "kasubsiUrikes", // Data Kes E
  "kasiKesud", // Data Kes E
  // "kasiMinkes", // @todo Approval apa?

  "kasubsiJasrek", // Data Watpers B
  "kasiWatpers", // Data Watpers B
  // "kasiBintal", // @todo Approval apa?

  // "kasiKum", // Data Kum F, perlu approval?

  "kasubsiEvadal", // Data Profesi Penerbang D
  "kasiProfesi", // Data Profesi Penerbang D
];

/**
 * @todo each portal should have its own nav
 */
// const basicNav: SidebarItemProps[] = [];
const basicSisfopersNav: SidebarItemProps[] = [
  {
    Icon: (props: IconBaseProps) => (
      <MdDashboard size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: sisfopersRoutes.dashboard,
    title: "Dashboard",
    kiddos: [
      {
        title: "Overview",
        shrinkTitle: "OV",
        allowedUsers: [
          "kasubsiDalpersmil",
          "kasiDalpersmil",
          "kasiDalperssip",
          "kasubsiDatapers",
          "kasiMindik",
          "kasubditMinpers",
        ],
        kiddoURL: sisfopersRoutes.dashboard_overview,
      },
      {
        title: "Dashboard Personel",
        shrinkTitle: "DP",
        allowedUsers: [
          "kasubsiDalpersmil",
          "kasiDalpersmil",
          "kasiDalperssip",
          "kasubsiDatapers",
          "kasiMindik",
          "kasubditMinpers",
        ],
        kiddoURL: sisfopersRoutes.dashboard_personel,
      },
      {
        title: "Dashboard Hukum",
        shrinkTitle: "DH",
        allowedUsers: [
          "kasubsiJasrek",
          "kasiWatpers",
          "kasiBintal",
          "kasiKum",
          "kasubsiEvadal",
          "kasiProfesi",
          "kasubditWatpers",
        ],
        kiddoURL: sisfopersRoutes.dashboard_hukum,
      },
      {
        title: "Dashboard Kesehatan",
        shrinkTitle: "DK",
        allowedUsers: [
          "kasubsiUrikes",
          "kasiKesud",
          "kasiMinkes",
          "kasubditKes",
        ],
        kiddoURL: sisfopersRoutes.dashboard_kesehatan,
      },
    ],
    allowedUsers: ["kasi", "kasubsi", "kasubdit"],
  },
  {
    Icon: (props: IconBaseProps) => (
      <MdViewList size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: sisfopersRoutes.management_personel,
    title: "Manajemen",
    kiddos: [
      {
        title: "Personel Aktif",
        shrinkTitle: "PA",
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
        kiddoURL: sisfopersRoutes.management_personel_active,
      },
      {
        title: "Personel Tidak Aktif",
        shrinkTitle: "PT",
        allowedUsers: ["kasi", "kasubsi", "kasubdit"],
        kiddoURL: sisfopersRoutes.management_personel_inactive,
      },
      {
        title: "Personel Pensiun",
        shrinkTitle: "PUN",
        allowedUsers: ["kasiWatpers"],
        kiddoURL: sisfopersRoutes.management_retirement_recommendation,
      },
      {
        title: "Master Pangkat",
        shrinkTitle: "PKT",
        allowedUsers: ["kasubsiDatapers"],
        kiddoURL: sisfopersRoutes.management_rank,
      },
      {
        title: "Pangkat",
        shrinkTitle: "PKT",
        allowedUsers: ["kasiDalpersmil"],
        kiddoURL: sisfopersRoutes.management_eligible_rank,
      },
      {
        title: "Master Pendidikan",
        shrinkTitle: "PDK",
        allowedUsers: ["kasubsiDatapers"],
        kiddoURL: sisfopersRoutes.management_pendidikan,
      },
      {
        title: "Master Korps",
        shrinkTitle: "KR",
        allowedUsers: ["kasubsiDatapers"],
        kiddoURL: sisfopersRoutes.management_korps,
      },
      {
        title: "Master Jabatan",
        shrinkTitle: "MJB",
        allowedUsers: ["kasubsiDatapers"],
        kiddoURL: sisfopersRoutes.management_jabatan,
      },
      {
        title: "Jabatan",
        shrinkTitle: "JBT",
        allowedUsers: ["kasiDalpersmil", "kasubsiDatapers"],
        kiddoURL: sisfopersRoutes.management_rekomendasi_jabatan,
      },
      {
        title: "Satker",
        shrinkTitle: "SKR",
        allowedUsers: ["kasubsiDatapers"],
        kiddoURL: sisfopersRoutes.management_satker,
      },
      {
        title: "Master Keahlian",
        shrinkTitle: "KH",
        allowedUsers: ["kasubsiDatapers"],
        kiddoURL: sisfopersRoutes.management_keahlian,
      },
      {
        title: "Akun",
        shrinkTitle: "AK",
        allowedUsers: ["superadmin"],
        kiddoURL: sisfopersRoutes.management_account,
      },
      {
        title: "Alur Kerja",
        shrinkTitle: "AL",
        allowedUsers: ["superadmin"],
        kiddoURL: sisfopersRoutes.management_workflow,
      },
      {
        title: "Approval",
        shrinkTitle: "AP",
        kiddoURL: sisfopersRoutes.management_approval,
        allowedUsers: allowedForApprovalPage,
      },
      {
        title: "Kasus",
        shrinkTitle: "KA",
        kiddoURL: sisfopersRoutes.management_kasus,
        allowedUsers: ["kasiKum"],
      },
      {
        title: "Master Profesi",
        shrinkTitle: "PR",
        allowedUsers: ["kasiProfesi"],
        kiddoURL: sisfopersRoutes.management_profession,
      },
      {
        title: "Master Kualifikasi",
        shrinkTitle: "KU",
        kiddoURL: sisfopersRoutes.management_qualification,
        allowedUsers: ["kasiProfesi"],
      },
    ],
    allowedUsers: ["superadmin", "kasi", "kasubsi", "kasubdit"],
  },
  {
    Icon: (props: IconBaseProps) => (
      <MdOutlineDescription
        size={18}
        color={COLORS.white}
        fill={COLORS.white}
      />
    ),
    parentURL: sisfopersRoutes.reports,
    title: "Laporan",
    allowedUsers: ["kasi", "kasubsi", "kasubdit"],
  },
  {
    Icon: (props: IconBaseProps) => (
      <GoChecklist size={18} color={COLORS.white} fill={COLORS.white} />
    ),

    kiddos: [
      {
        title: "Penerbangan",
        shrinkTitle: "KP",
        allowedUsers: ["kasiProfesi"],
        kiddoURL: sisfopersRoutes.qualification_flight_personel,
      },
    ],
    title: "Kualifikasi",
    allowedUsers: ["kasiProfesi"],
  },
  {
    Icon: (props: IconBaseProps) => (
      <RiAccountBoxFill size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: sisfopersRoutes.profile,
    title: "Data Personel",
    allowedUsers: ["user"],
  },
  {
    Icon: (props: IconBaseProps) => (
      <MdWarning size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: sisfopersRoutes.alert,
    title: "Peringatan",
    allowedUsers: ["kasi", "kasubsi", "kasubdit", "user"],
  },
  {
    Icon: (props: IconBaseProps) => (
      <MdOutlineAccountCircle
        size={18}
        color={COLORS.white}
        fill={COLORS.white}
      />
    ),
    parentURL: sisfopersRoutes.account,
    title: "Akun",
    kiddos: [
      {
        title: "Ubah Password",
        shrinkTitle: "UP",
        kiddoURL: sisfopersRoutes.account_changePwd,
      },
      {
        title: "GENERATE OTP",
        shrinkTitle: "GO",
        kiddoURL: sisfopersRoutes.account_getOTP,
      },
    ],
  },
  {
    Icon: (props: IconBaseProps) => (
      <MdOutlineBackup size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: sisfopersRoutes.importFile,
    title: "Import",
    allowedUsers: ["kasi"],
  },
  {
    Icon: (props: IconBaseProps) => (
      <RiAccountBoxFill size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: sisfopersRoutes.personelFile,
    title: "File",
    allowedUsers: ["user"],
  },
  {
    Icon: (props: IconBaseProps) => (
      <MdOutlineBackup size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: sisfopersRoutes.backup_restore,
    title: "Pencadangan",
    kiddos: [
      {
        title: "Backup",
        shrinkTitle: "BK",
        kiddoURL: sisfopersRoutes.backup,
      },
      {
        title: "Restore",
        shrinkTitle: "RS",
        kiddoURL: sisfopersRoutes.restore,
      },
    ],
    allowedUsers: ["superadmin"],
  },
  {
    Icon: (props: IconBaseProps) => (
      <MdOutlineLogout size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: sisfopersRoutes.logout,
    title: "Keluar",
  },
];

const basicSisfologNav: SidebarItemProps[] = [
  {
    Icon: (props: IconBaseProps) => (
      <MdDashboard size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: sisfologRoutes.dashboard,
    title: "Dashboard",
    allowedUsers: ["kasi", "kasubsi", "kasubdit"],
  },
  {
    Icon: (props: IconBaseProps) => (
      <RiAccountBoxFill size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: sisfologRoutes.profile,
    title: "DATA PERSONEL",
    allowedUsers: ["user"],
  },
  {
    Icon: (props: IconBaseProps) => (
      <MdOutlineLogout size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: sisfologRoutes.logout,
    title: "Keluar",
  },
];

const basicSisfoopsNav: SidebarItemProps[] = [
  {
    Icon: (props: IconBaseProps) => (
      <MdDashboard size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: sisfologRoutes.dashboard,
    title: "Dashboard",
    allowedUsers: ["kasi", "kasubsi", "kasubdit"],
  },
  {
    Icon: (props: IconBaseProps) => (
      <RiAccountBoxFill size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: sisfologRoutes.profile,
    title: "DATA PERSONEL",
    allowedUsers: ["user"],
  },
  {
    Icon: (props: IconBaseProps) => (
      <MdOutlineLogout size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: sisfologRoutes.logout,
    title: "Keluar",
  },
];

function App() {
  // const sisfologRoutes = useSisfologRoutes();
  // const sisfoopsRoutes = useSisfoopsRoutes();
  // const sisfopersRoutes = useSisfopersRoutes();
  // const usertypeName = useUsertypeName();

  return (
    <>
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={
              <AuthHOC>
                <Outlet />
              </AuthHOC>
            }
          >
            {/* 
              @todo need to create 1 portal page component where user can choose sisfopers, sisfoops, etc
            */}
            <Route
              index
              element={<Navigate to={`${ListOfRoutes.loginpage}/sisfo-pers`} />}
            />

            {/* @todo Bisa diextract ke router2nya sendiri */}
            <Route
              path={sisfopersRoutes.baseURL}
              element={
                // @todo setiap sisfo punya navigation sendiri2

                <Layout sidebarItems={basicSisfopersNav}>
                  <AuthHOC>
                    <Outlet />
                  </AuthHOC>
                </Layout>
              }
            >
              <Route index element={<Redirector />} />
              <Route
                path={sisfopersRoutes.dashboard_overview}
                element={
                  <UserGate
                    allowedUsers={[
                      "kasubsiDalpersmil",
                      "kasiDalpersmil",
                      "kasiDalperssip",
                      "kasubsiDatapers",
                      "kasiMindik",
                      "kasubditMinpers",
                    ]}
                  >
                    <OverviewDashboardPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.dashboard_personel}
                element={
                  <UserGate
                    allowedUsers={[
                      "kasubsiDalpersmil",
                      "kasiDalpersmil",
                      "kasiDalperssip",
                      "kasubsiDatapers",
                      "kasiMindik",
                      "kasubditMinpers",
                    ]}
                  >
                    <PersonelDashboardPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.dashboard_hukum}
                element={
                  <UserGate
                    allowedUsers={[
                      "kasubsiJasrek",
                      "kasiWatpers",
                      "kasiBintal",
                      "kasiKum",
                      "kasubsiEvadal",
                      "kasiProfesi",
                      "kasubditWatpers",
                    ]}
                  >
                    <CaseDashboardPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.dashboard_kesehatan}
                element={
                  <UserGate
                    allowedUsers={[
                      "kasubsiUrikes",
                      "kasiKesud",
                      "kasiMinkes",
                      "kasubditKes",
                    ]}
                  >
                    <GarjesDashboardPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.profile}
                element={<PersonilViewPage />}
              />
              <Route
                path={sisfopersRoutes.reports}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <ReportPage />
                  </UserGate>
                }
              />

              <Route
                path={`${sisfopersRoutes.management_personel}/:isActive`}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <ActivePersonelManagementPage />
                  </UserGate>
                }
              />
              <Route
                path={`${sisfopersRoutes.management_approval}`}
                element={
                  <UserGate allowedUsers={allowedForApprovalPage}>
                    <ApprovalPersonelPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.management_rank}
                element={
                  <UserGate allowedUsers={["kasubsiDatapers"]}>
                    <RankManagementPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.management_eligible_rank}
                element={
                  <UserGate
                    allowedUsers={["kasubsiDalpersmil", "kasiDalpersmil"]}
                  >
                    <EligibleRankPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.management_retirement_recommendation}
                element={
                  <UserGate allowedUsers={["kasiWatpers"]}>
                    <RetirementRecommendationPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.management_pendidikan}
                element={
                  <UserGate allowedUsers={["kasubsiDatapers"]}>
                    <MasterDataEducationManagementPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.management_jabatan}
                element={
                  <UserGate allowedUsers={["kasubsiDatapers"]}>
                    <PositionManagementPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.management_rekomendasi_jabatan}
                element={
                  <UserGate
                    allowedUsers={[
                      "kasiDalpersmil",
                      "kasubsiDalpersmil",
                      "kasubsiDatapers",
                    ]}
                  >
                    <RecomendationPositionManagementPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.management_korps}
                element={
                  <UserGate allowedUsers={["kasubsiDatapers"]}>
                    <MasterDataKorpsManagementPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.management_satker}
                element={
                  <UserGate allowedUsers={["kasubsiDatapers"]}>
                    <SatkerManagementPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.management_profession}
                element={
                  <UserGate allowedUsers={["kasiProfesi"]}>
                    <MasterDataPositionProfessionManagementPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.management_qualification}
                element={
                  <UserGate allowedUsers={["kasiProfesi"]}>
                    <MasterDataQualificationManagementPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.management_keahlian}
                element={
                  <UserGate allowedUsers={["kasubsiDatapers"]}>
                    <MasterDataKeahlianManagementPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.management_kasus}
                element={
                  <UserGate allowedUsers={["kasiKum"]}>
                    <CaseManagementPage />
                  </UserGate>
                }
              />
              {/* 
              @todo replace this element with actual personel view
              */}
              <Route
                path={`${sisfopersRoutes.management_personel_view}/:id`}
                element={<PersonilViewPage />}
              />
              <Route
                path={`${sisfopersRoutes.qualification_flight_personel}`}
                element={<FlightQualificationPage />}
              />
              <Route
                path={sisfopersRoutes.notification}
                element={<NotificationPage />}
              />
              <Route
                path={sisfopersRoutes.management_account}
                element={<AccountViewPage />}
              />
              <Route
                path={sisfopersRoutes.management_workflow}
                element={<WorkflowPage />}
              />
              <Route
                path={sisfopersRoutes.backup}
                element={
                  <UserGate allowedUsers={["superadmin"]}>
                    <BackupPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.alert}
                element={
                  <UserGate
                    allowedUsers={["kasi", "kasubdit", "kasubsi", "user"]}
                  >
                    <AlertPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.importFile}
                element={
                  <UserGate allowedUsers={["kasi"]}>
                    <ImportPage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.restore}
                element={
                  <UserGate allowedUsers={["superadmin"]}>
                    <RestorePage />
                  </UserGate>
                }
              />
              <Route
                path={sisfopersRoutes.logout}
                element={<SisfoPersLogoutPage />}
              />
              <Route
                path={sisfopersRoutes.account_changePwd}
                element={<ChangePwdPage />}
              />
              <Route
                path={sisfopersRoutes.account_getOTP}
                element={<GetOtpPage />}
              />
              <Route
                path={sisfopersRoutes.personelFile}
                element={<PersonelFilePage />}
              />
            </Route>

            {/* @todo Bisa diextract ke router2nya sendiri */}
            <Route
              path={sisfologRoutes.baseURL}
              element={
                // @todo setiap sisfo punya navigation sendiri2
                <Layout sidebarItems={basicSisfologNav}>
                  <Outlet />
                </Layout>
              }
            >
              <Route index element={<Redirector />} />
              <Route
                path={sisfologRoutes.dashboard}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <HomePage />
                  </UserGate>
                }
              />
              <Route
                path={sisfologRoutes.profile}
                element={<PersonilViewPage />}
              />
              <Route
                path={sisfologRoutes.logout}
                element={<SisfoPersLogoutPage />}
              />
            </Route>

            {/* @todo Bisa diextract ke router2nya sendiri */}
            <Route
              path={sisfoopsRoutes.baseURL}
              element={
                // @todo setiap sisfo punya navigation sendiri2
                <Layout sidebarItems={basicSisfoopsNav}>
                  <Outlet />
                </Layout>
              }
            >
              <Route index element={<Redirector />} />
              <Route
                path={sisfoopsRoutes.dashboard}
                element={
                  <UserGate allowedUsers={["kasi", "kasubsi", "kasubdit"]}>
                    <HomePage />
                  </UserGate>
                }
              />
              <Route
                path={sisfoopsRoutes.profile}
                element={<PersonilViewPage />}
              />
              <Route
                path={sisfoopsRoutes.logout}
                element={<SisfoPersLogoutPage />}
              />
            </Route>
          </Route>
          <Route
            path={ListOfRoutes.loginpage}
            element={
              <React.Suspense fallback={<LoaderPage />}>
                <Outlet />
              </React.Suspense>
            }
          >
            {
              // @todo
              // Setiap Portal bisa mempunyai loginpagenya sendiri2.
            }
            <Route path={sisfopersRoutes.login} element={<LoginPage />} />
            <Route path={sisfologRoutes.login} element={<LoginPage />} />
            <Route path={sisfoopsRoutes.login} element={<LoginPage />} />
            <Route
              index
              element={<Navigate to={`${ListOfRoutes.loginpage}/sisfo-pers`} />}
            />
          </Route>

          <Route path="*" element={<NoMatch />} />
        </Routes>
      </HashRouter>
      {/* <ToastContainer /> */}
      <StyledToastContainerPuspenerbal />
    </>
  );
}

export default App;
