import { AlertTypeEnum, IAlert, IAlertList } from "types/endpoints/alert";
import { personelStoreAPI } from "./personelStoreAPI";
import { convertToEncodedURL } from "assets/usefulFunctions";
import { BackendDataShape } from "api/config";

export const personelAlertStoreAPI = personelStoreAPI.injectEndpoints({
  endpoints: (builder) => {
    return {
      getAlert: builder.query<IAlertList[], Partial<any>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/personel/alert" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<IAlertList[]>) =>
          response.data,
        providesTags: ["PersonelAlertList"],
      }),
      getAlertById: builder.query<
        IAlert<AlertTypeEnum>,
        Partial<{ id: number }>
      >({
        query: (obj) => {
          let url = `/personel/alert/${obj.id}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<IAlert<AlertTypeEnum>>,
        ) => response.data,
        providesTags: (_, __, { id }) => [
          "PersonelAlertList",
          { type: "PersonelAlertList", id },
        ],
      }),
    };
  },
});

export const {
  useGetAlertQuery,
  useGetAlertByIdQuery,
  util: { resetApiState: resetPersonelAlertStoreAPI },
} = personelAlertStoreAPI;
