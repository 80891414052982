import React from "react";
import { AUsertype, findUsertype } from "api/credentialsAPI/usertype";
import { useAppDispatch } from ".";
import { BasicSelectOpt } from "types";
import { useGetUsertypeQuery } from "store/usertypeStore/usertypeStoreAPI";

export interface AUserOpt extends BasicSelectOpt<number>, AUsertype {}

const useUserTypeValue = () => {
  const dispatch = useAppDispatch();
  const [userTypeList, setUserTypeList] = React.useState<AUsertype[]>();
  const [Loading, setLogLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLogLoading(true);
    findUsertype()
      .then(({ data: { data } }) => {
        let opt = data;
        setUserTypeList(opt);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLogLoading(false);
      });
  }, [dispatch]);
  return {
    userTypeList,
    Loading,
  };
};

const useUserOpts = () => {
  const {
    data: userTypeList,
    isLoading: Loading,
    isFetching,
  } = useGetUsertypeQuery({});
  const isLoading = Loading || isFetching;
  console.log(userTypeList);
  if (!userTypeList || userTypeList.length < 1)
    return {
      arr: [],
      arrRoles: [],
      isLoading,
    };
  return {
    arr: userTypeList.map((aCmp) => {
      return {
        label: aCmp.name,
        value: aCmp.id,
        ...aCmp,
      };
    }),

    arrRoles: userTypeList.map((val) => {
      return {
        label: `${val.role} - ${val.name}`,
        value: val.id,
        role: val.role,
        usertypeName: val.name,
      };
    }),
    isLoading,
  };
};

export { useUserTypeValue, useUserOpts };
