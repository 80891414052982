import { widthBreakpoints as breakPoints, mq } from "../assets/theme";

export const extraExtraLarge = mq[8];

export const extraLarger = `@media screen and (max-width:${breakPoints[6]}px)`;

export const extraLarge = `@media screen and (max-width:${breakPoints[5]}px)`;

export const large = `@media screen and (max-width:${breakPoints[4]}px)`;

export const medium = `@media screen and (max-width:${breakPoints[3]}px)`;

export const small = `@media screen and (max-width:${breakPoints[1]}px)`;

export const xSmall = `@media screen and (max-width:${breakPoints[0]}px)`;

export const onlyForChart = "@media screen and (max-width:1200px)";
