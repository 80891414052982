import { UsedAPI } from "../api/config";

/**
 * @todo clear this ListOfRoutes from rapidsense routes and add new Puspenerbal paths
 */
export const ListOfRoutes = {
  homepage: "/",
  loginpage: "/login",

  dashboard: "/dashboard",

  management: "/management",

  reports: "/reports",
  /**
   *  @deprecated clear this and its respective pages
   */
  schedule: "/schedule",
  /**
   *  @deprecated clear this and its respective pages
   * @todo add links for another state such as
   * verify: "/verification/:verifyState"
   *
   * so then we can show page depending on the `verifyState`
   */
  verify: "/verification/success",

  /**
   *  @deprecated clear this and its respective pages
   * Dedicated machine OEE, used by operator
   */
  machineDetails: "/machine",

  profile: "/profile",
} as const;

export const ReportLinks = {
  filter_by_caseLog: (params: string) => `${UsedAPI}/case${params}`,
} as const;

export const DashboardPuspenerbalLinks = {
  get dashboard_by_dsp(): string {
    return `${UsedAPI}/dashboard/rekap_dsp`;
  },

  get dashboard_by_aktifitas_penerbangan(): string {
    return `${UsedAPI}/dashboard/aktivitas_penerbangan`;
  },
} as const;

export class BaseSisfoRoutes {
  constructor(public baseURL: string) {}

  get home(): string {
    return `${this.baseURL}`;
  }

  get dashboard(): string {
    return `${this.baseURL}/dashboard`;
  }

  get login(): string {
    return `/login/${this.baseURL}`;
  }

  get notification(): string {
    return `${this.baseURL}/notification`;
  }
  get reports(): string {
    return `${this.baseURL}/reports`;
  }
  get alert(): string {
    return `${this.baseURL}/alert`;
  }

  /**
   * @todo profile should only be in sisfopers
   */
  get profile(): string {
    return `${this.baseURL}/profile`;
  }

  get logout(): string {
    return `${this.baseURL}/logout`;
  }
}

export class SisfopersRoutes extends BaseSisfoRoutes {
  constructor() {
    super("/sisfo-pers");
  }

  get dashboard_overview(): string {
    return `${this.dashboard}/overview`;
  }
  get dashboard_personel(): string {
    return `${this.dashboard}/personel`;
  }

  get dashboard_hukum(): string {
    return `${this.dashboard}/hukum`;
  }

  get dashboard_kesehatan(): string {
    return `${this.dashboard}/kesehatan`;
  }

  get qualification(): string {
    return `${this.home}/qualification`;
  }
  get qualification_flight_personel(): string {
    return `${this.qualification}/flight-personel`;
  }
  get management(): string {
    return `${this.home}/management`;
  }

  get management_personel(): string {
    return `${this.management}/personel`;
  }

  get management_rank(): string {
    return `${this.management}/rank`;
  }
  get management_retirement_recommendation(): string {
    return `${this.management}/retirement-recommendation`;
  }
  get management_eligible_rank(): string {
    return `${this.management}/eligible-rank`;
  }
  get management_pendidikan(): string {
    return `${this.management}/education`;
  }
  get management_korps(): string {
    return `${this.management}/korps`;
  }
  get management_satker(): string {
    return `${this.management}/satker`;
  }
  get management_profession(): string {
    return `${this.management}/profession`;
  }
  get management_qualification(): string {
    return `${this.management}/qualification`;
  }
  get management_keahlian(): string {
    return `${this.management}/keahlian`;
  }
  get management_jabatan(): string {
    return `${this.management}/jabatan`;
  }
  get management_rekomendasi_jabatan(): string {
    return `${this.management}/rekomendasi-jabatan`;
  }

  get management_superadmin(): string {
    return `${this.management}/superadmin`;
  }

  get management_kasus(): string {
    return `${this.management}/case`;
  }

  get management_account(): string {
    return `${this.management_superadmin}/account`;
  }

  get management_workflow(): string {
    return `${this.management_superadmin}/workflow`;
  }

  get management_approval(): string {
    return `${this.management}/approval`;
  }

  get management_personel_active(): string {
    return `${this.management_personel}/active`;
  }
  get management_personel_inactive(): string {
    return `${this.management_personel}/inactive`;
  }

  getManagementPersonelInActive(params: string): string {
    return `${this.management_personel_inactive}${params}`;
  }

  get management_personel_view(): string {
    return `${this.management_personel}/view`;
  }

  /**
   *
   * @param id id of the personel
   * @returns URL for this personel management
   */
  getSpecificPersonel(id: number): string {
    return `${this.management_personel_view}/${id}`;
  }

  get viewDetailPersonil(): string {
    return `${this.management}/personil/:id`;
  }

  get account(): string {
    return `${this.home}/account`;
  }

  get account_changePwd(): string {
    return `${this.account}/change-password`;
  }

  get account_getOTP(): string {
    return `${this.account}/otp`;
  }

  get backup_restore(): string {
    return `${this.home}/backup-restore`;
  }

  get backup_personel_aktif(): string {
    return `${this.backup_restore}/personel-aktif`;
  }

  get backup_personel_nonaktif(): string {
    return `${this.backup_restore}/personel-nonaktif`;
  }

  get backup_rank(): string {
    return `${this.backup_restore}/pangkat`;
  }

  get backup_korps(): string {
    return `${this.backup_restore}/korps`;
  }

  get restore(): string {
    return `${this.backup_restore}/restore`;
  }

  get backup(): string {
    return `${this.backup_restore}/backup`;
  }

  get personelFile(): string {
    return `${this.home}/personel-file`;
  }

  get importFile(): string {
    return `${this.baseURL}/import-file`;
  }
}

export class SisfologRoutes extends BaseSisfoRoutes {
  constructor() {
    super("/sisfo-log");
  }
}

export class SisfoOpsRoutes extends BaseSisfoRoutes {
  constructor() {
    super("/sisfo-ops");
  }
}
