import { BackendDataShape } from "api/config";

import { convertToEncodedURL, jsonToFormData } from "assets/usefulFunctions";

import { AMasterDataKeahlian } from "types/endpoints/masterData/masterData";

import { masterDataStoreAPI } from "../masterDataStoreAPI";

export const masterDataSkillStoreAPI = masterDataStoreAPI.injectEndpoints({
  endpoints: (builder) => {
    return {
      getSkillList: builder.query({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/keahlian" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<AMasterDataKeahlian[]>,
        ) => response.data,
        providesTags: ["SkillList"],
      }),
      createSkill: builder.mutation<
        BackendDataShape<object>,
        Partial<AMasterDataKeahlian>
      >({
        query: (obj) => {
          return {
            url: "/keahlian",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["SkillList"],
      }),
      updateSkill: builder.mutation<
        BackendDataShape<object>,
        Partial<AMasterDataKeahlian>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: "/keahlian/" + id,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["SkillList"],
      }),

      deleteSkill: builder.mutation<BackendDataShape<object>, { id: number }>({
        query: ({ id }) => {
          return {
            url: `/keahlian/` + id,
            method: "DELETE",
          };
        },
        invalidatesTags: ["SkillList"],
      }),

      restoreSkill: builder.mutation<BackendDataShape<object>, { file: File }>({
        query: ({ ...rest }) => {
          return {
            url: "/keahlian/restore",
            body: jsonToFormData(rest),
            method: "POST",
          };
        },
        invalidatesTags: ["SkillList"],
      }),
    };
  },
});

export const {
  useGetSkillListQuery,
  useRestoreSkillMutation,
  useCreateSkillMutation,
  useUpdateSkillMutation,
  useDeleteSkillMutation,
  util: { resetApiState: resetMasterDataSkillAPI },
} = masterDataSkillStoreAPI;
