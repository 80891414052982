import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { convertToEncodedURL, jsonToFormData } from "assets/usefulFunctions";

import {
  APositionRecomendation,
  APositionRecomendationObj,
} from "types/endpoints/position";

export const recommendPositionStoreAPI = createApi({
  reducerPath: "recommendPositionStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["PositionRecomendationList"],
  endpoints: (builder) => {
    return {
      getPositionRecommendation: builder.query({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/jabatanRecommendation" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<APositionRecomendation[]>,
        ) => response.data,
        providesTags: ["PositionRecomendationList"],
      }),

      createPositionRecommendation: builder.mutation<
        BackendDataShape<Pick<APositionRecomendation, "id">[]>,
        Partial<APositionRecomendationObj>
      >({
        query: (obj) => {
          return {
            url: "/jabatanRecommendation",
            body: obj,
            method: "POST",
          };
        },
        transformResponse: (
          response: BackendDataShape<APositionRecomendation[]>,
        ) => response,
        invalidatesTags: ["PositionRecomendationList"],
      }),

      updatePositionRecommendation: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<APositionRecomendationObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/jabatanRecommendation/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["PositionRecomendationList"],
      }),
      deletePositionRecommendation: builder.mutation<
        BackendDataShape<object>,
        { id: number }
      >({
        query: ({ id }) => {
          return {
            url: `/jabatanRecommendation/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["PositionRecomendationList"],
      }),
      updatePositionSK: builder.mutation<
        BackendDataShape<object>,
        object & { id: number; type: string }
      >({
        query: ({ id, type, ...rest }) => {
          const data = jsonToFormData(rest);
          return {
            url: `/personel/${id}/${type}/sk`,
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["PositionRecomendationList"],
      }),

      restorePositionRecommendation: builder.mutation<
        BackendDataShape<object>,
        { file: File }
      >({
        query: ({ ...rest }) => {
          return {
            url: "/jabatanRecommendation/restore",
            body: jsonToFormData(rest),
            method: "POST",
          };
        },
        invalidatesTags: ["PositionRecomendationList"],
      }),
    };
  },
});

export const {
  useGetPositionRecommendationQuery,
  useUpdatePositionRecommendationMutation,
  useCreatePositionRecommendationMutation,
  useDeletePositionRecommendationMutation,
  useUpdatePositionSKMutation,
  useRestorePositionRecommendationMutation,
  util: { resetApiState: resetRecommendPositionStoreAPI },
} = recommendPositionStoreAPI;
