import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BackendDataShape, UsedAPI } from "../../../api/config";
import CookieMonster from "../../../api/CookieMonster";
import { convertToEncodedURL } from "../../../assets/usefulFunctions";
import { AAsset } from "../../../types/endpoints/asset";

export const assetStoreAPI = createApi({
  reducerPath: "assetStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["AssetList"],
  endpoints: (builder) => {
    return {
      getAsset: builder.query<AAsset[], Partial<AAsset>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/asset" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AAsset[]>) =>
          response.data,
        providesTags: ["AssetList"],
      }),
    };
  },
});
export const {
  useGetAssetQuery,
  util: { resetApiState: resetAssetStoreAPI },
} = assetStoreAPI;
