import { BackendDataShape } from "../../../api/config";
import {
  convertToEncodedURL,
  jsonToFormData,
} from "../../../assets/usefulFunctions";
import { APersonelApproval } from "../../../types/endpoints/personel/approval";
import { personelStoreAPI } from "./personelStoreAPI";

export const personelApprovalStoreAPI = personelStoreAPI.injectEndpoints({
  // reducerPath: "personelApprovalStoreAPI",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: `${UsedAPI}/approvals`,
  //   prepareHeaders: (headers) => {
  //     let token = CookieMonster.loadCookie("jwt");
  //     headers.set("Authorization", `Bearer ${token}`);
  //     return headers;
  //   },
  // }),
  // tagTypes: ["PersonelAdditionalDataList"],
  endpoints: (builder) => {
    return {
      getApprovals: builder.query<
        APersonelApproval<any>[],
        Partial<APersonelApproval<any>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/approvals/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<APersonelApproval<any>[]>,
        ) => response.data,
        providesTags: ["PersonelAdditionalDataList"],
      }),
      approvePersonelInformation: builder.mutation<
        BackendDataShape<object>,
        { id: string }
      >({
        query: (obj) => {
          return {
            url: "/approvals/approve",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["PersonelAdditionalDataList"],
      }),
      rejectPersonelInformation: builder.mutation<
        BackendDataShape<object>,
        { id: number; rejectedNote: string }
      >({
        query: (obj) => {
          return {
            url: "/approvals/reject",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["PersonelAdditionalDataList"],
      }),
      updatePersonelApprovalInformation: builder.mutation<
        BackendDataShape<object>,
        { id: number }
      >({
        query: ({ id, ...rest }) => {
          const data = jsonToFormData(rest);
          return {
            url: "/approvals/update/" + id,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["PersonelAdditionalDataList"],
      }),
      updatePersonelApprovalInformationRejected: builder.mutation<
        BackendDataShape<object>,
        { id: number; data: object }
      >({
        query: (obj) => {
          return {
            url: "/approvals/update",
            body: obj,
            method: "PATCH",
          };
        },
        invalidatesTags: ["PersonelAdditionalDataList"],
      }),
    };
  },
});

export const {
  useGetApprovalsQuery,
  useApprovePersonelInformationMutation,
  useRejectPersonelInformationMutation,
  useUpdatePersonelApprovalInformationRejectedMutation,
  useUpdatePersonelApprovalInformationMutation,
  util: { resetApiState: resetApprovalsStoreAPI },
} = personelApprovalStoreAPI;
