import { BackendDataShape } from "api/config";

import { RequireSome } from "types";
import {
  AMasterDataEducation,
  AMasterDataEducationEquality,
  AMasterDataEducationReqObj,
} from "types/endpoints/masterData/masterData";

import { convertToEncodedURL, jsonToFormData } from "assets/usefulFunctions";

import { masterDataStoreAPI } from "../masterDataStoreAPI";

export const masterDataEducationStoreAPI = masterDataStoreAPI.injectEndpoints({
  endpoints: (builder) => {
    return {
      getMasterDataEducationList: builder.query({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/pendidikan" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<AMasterDataEducation[]>,
        ) => response.data,
        providesTags: ["EducationList"],
      }),
      getMasterDataEducationEqualityList: builder.query<
        AMasterDataEducationEquality[],
        RequireSome<AMasterDataEducationEquality, "id">
      >({
        query: ({ id, ...rest }) => {
          let params = "";
          if (rest) {
            params = convertToEncodedURL(rest);
            params = params.length ? "?" + params : "";
          }
          let url = `/pendidikan/${id}/pendidikanKesetaraan` + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<AMasterDataEducationEquality[]>,
        ) => response.data,
        providesTags: (_, __, c) => [
          "EducationEqualityList",
          { id: c.id, type: "EducationEqualityList" },
        ],
      }),
      createMasterDataEducation: builder.mutation<
        BackendDataShape<object>,
        Partial<AMasterDataEducationReqObj>
      >({
        query: (obj) => {
          return {
            url: "/pendidikan",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["EducationList"],
      }),
      createMasterDataEducationEquality: builder.mutation<
        BackendDataShape<object>,
        Partial<AMasterDataEducationReqObj> & { id: number }
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/pendidikan/${id}/pendidikan_kesetaraan`,
            body: rest,
            method: "POST",
          };
        },
        invalidatesTags: ["EducationEqualityList"],
      }),
      updateMasterDataEducation: builder.mutation<
        BackendDataShape<object>,
        Partial<AMasterDataEducationReqObj> & { id: number }
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/pendidikan/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["EducationList"],
      }),
      updateMasterDataEducationEquality: builder.mutation<
        BackendDataShape<object>,
        Partial<AMasterDataEducationReqObj> & {
          id: number;
          idEducationEquality: number;
        }
      >({
        query: ({ id, idEducationEquality, ...rest }) => {
          return {
            url: `/pendidikan/${id}/pendidikanKesetaraan/${idEducationEquality}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["EducationEqualityList"],
      }),
      deleteMasterDataEducation: builder.mutation<
        BackendDataShape<object>,
        { id: number }
      >({
        query: ({ id }) => {
          return {
            url: `/pendidikan/` + id,
            method: "DELETE",
          };
        },
        invalidatesTags: ["EducationList"],
      }),
      deleteMasterDataEducationEquality: builder.mutation<
        BackendDataShape<object>,
        { id: number; idEquality: number }
      >({
        query: ({ id, idEquality }) => {
          return {
            url: `/pendidikan/${id}/pendidikanKesetaraan/${idEquality}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["EducationEqualityList"],
      }),

      restoreMasterDataEducation: builder.mutation<
        BackendDataShape<object>,
        { file: File }
      >({
        query: ({ ...rest }) => {
          return {
            url: "/pendidikan/restore",
            body: jsonToFormData(rest),
            method: "POST",
          };
        },
        invalidatesTags: ["EducationEqualityList"],
      }),
    };
  },
});

export const {
  useGetMasterDataEducationEqualityListQuery,
  useGetMasterDataEducationListQuery,
  useCreateMasterDataEducationEqualityMutation,
  useCreateMasterDataEducationMutation,
  useUpdateMasterDataEducationEqualityMutation,
  useUpdateMasterDataEducationMutation,
  useDeleteMasterDataEducationEqualityMutation,
  useDeleteMasterDataEducationMutation,
  useRestoreMasterDataEducationMutation,
  util: { resetApiState: resetMasterDataEducationAPI },
} = masterDataEducationStoreAPI;
