import {
  Action,
  configureStore,
  isRejectedWithValue,
  Middleware,
  ThunkAction,
} from "@reduxjs/toolkit";
import userStore from "./userStore";
import sidebarStore from "./sidebarStore";
import { userStoreAPI } from "./userStore/userStoreAPI";
import { personelStoreAPI } from "./sisfopers/personelStore/personelStoreAPI";
import { usertypeStoreAPI } from "./usertypeStore/usertypeStoreAPI";
// import { personelApprovalStoreAPI } from "./sisfopers/personelStore/personelApprovalAPI";
import { lovStoreAPI } from "./sisfopers/lovStore/lovStoreAPI";
import { additionaltypeStoreAPI } from "./sisfopers/additionaltypeStore/additionaltypeStoreAPI";
import { caseStoreAPI } from "./sisfopers/caseStore/caseStoreAPI";
import { masterDataStoreAPI } from "./sisfopers/masterDataStore/masterDataStoreAPI";
import { recommendPositionStoreAPI } from "./sisfopers/recommendPositionStore/recommendPositionStoreAPI";
import { toast } from "react-toastify";
import { UnknownAsyncThunkRejectedWithValueAction } from "@reduxjs/toolkit/dist/matchers";
import { ErrorMessageBackendDataShape } from "../types";
import { dashboardStoreAPI } from "./sisfopers/dashboardStore/dashboardStoreAPI";
import { educationEqualityStoreAPI } from "./sisfopers/masterDataStore/education/educationEqualityStore";
import { assetStoreAPI } from "./sisfopers/assetStore/assetStoreAPI";
import { courseStoreAPI } from "./sisfopers/courseStore";
import { personelAlertStoreAPI } from "./sisfopers/personelStore/personelAlertStoreAPI";
import { personelFileStoreAPI } from "./filePersonelStore/filePersonelStoreAPI";
import { mainStoreAPI } from "./mainStore/mainStoreApi";

export const store = configureStore({
  /**
   * @todo sort these reducer slices alphabetically, ascending.
   */
  reducer: {
    userStore,
    sidebarStore,
    [additionaltypeStoreAPI.reducerPath]: additionaltypeStoreAPI.reducer,
    [personelAlertStoreAPI.reducerPath]: personelAlertStoreAPI.reducer,
    [caseStoreAPI.reducerPath]: caseStoreAPI.reducer,
    [lovStoreAPI.reducerPath]: lovStoreAPI.reducer,
    [masterDataStoreAPI.reducerPath]: masterDataStoreAPI.reducer,
    [personelStoreAPI.reducerPath]: personelStoreAPI.reducer,
    [assetStoreAPI.reducerPath]: assetStoreAPI.reducer,
    [recommendPositionStoreAPI.reducerPath]: recommendPositionStoreAPI.reducer,
    [userStoreAPI.reducerPath]: userStoreAPI.reducer,
    [usertypeStoreAPI.reducerPath]: usertypeStoreAPI.reducer,
    [dashboardStoreAPI.reducerPath]: dashboardStoreAPI.reducer,
    [educationEqualityStoreAPI.reducerPath]: educationEqualityStoreAPI.reducer,
    [courseStoreAPI.reducerPath]: courseStoreAPI.reducer,
    [personelFileStoreAPI.reducerPath]: personelFileStoreAPI.reducer,
    [mainStoreAPI.reducerPath]: mainStoreAPI.reducer,
  },
  middleware: (getMiddleWares) => {
    /**
     * @todo remove this logger if no longer needed
     */
    const logMiddleWare: Middleware = (api) => {
      return (next) => {
        return (action) => {
          if (isRejectedWithValue(action)) {
            const act = action as UnknownAsyncThunkRejectedWithValueAction;
            const payload = act.payload;

            const isBEErrorPayload = (
              payload: any,
            ): payload is ErrorMessageBackendDataShape => {
              return payload?.data?.status && payload.data.status === "error";
            };
            if (isBEErrorPayload(payload)) {
              toast.error(payload.data.message);
              // console.log(payload.data.message)
            }
          }
          return next(action);
        };
      };
    };

    /**
     * @todo sort these middlewares alphabetically, ascending.
     */
    let middlewares = getMiddleWares().concat([
      additionaltypeStoreAPI.middleware,
      caseStoreAPI.middleware,
      personelAlertStoreAPI.middleware,
      lovStoreAPI.middleware,
      logMiddleWare,
      masterDataStoreAPI.middleware,
      personelStoreAPI.middleware,
      assetStoreAPI.middleware,
      recommendPositionStoreAPI.middleware,
      userStoreAPI.middleware,
      usertypeStoreAPI.middleware,
      dashboardStoreAPI.middleware,
      educationEqualityStoreAPI.middleware,
      courseStoreAPI.middleware,
      personelFileStoreAPI.middleware,
      mainStoreAPI.middleware,
    ]);
    return middlewares;
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
