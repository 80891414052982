import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { jsonToFormData } from "assets/usefulFunctions";

import { ACourse } from "types/endpoints/personel/course";

export const courseStoreAPI = createApi({
  reducerPath: "courseStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["CourseList"],
  endpoints: (builder) => {
    return {
      createPersonelCourse: builder.mutation<
        BackendDataShape<ACourse>,
        any & { personelId: number }
      >({
        query: ({ personelId, ...rest }) => {
          const data = jsonToFormData(rest);
          return {
            url: `/personel/${personelId}/kursus`,
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["CourseList"],
      }),
      updatePersonelCourse: builder.mutation<
        BackendDataShape<ACourse>,
        object & { idAdditional: number; personelId: number }
      >({
        query: ({ idAdditional, personelId, ...rest }) => {
          const data = jsonToFormData(rest);
          return {
            url: `/personel/${personelId}/kursus/${idAdditional}`,
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["CourseList"],
      }),
    };
  },
});

export const {
  useCreatePersonelCourseMutation,
  useUpdatePersonelCourseMutation,
  util: { resetApiState: resetCourseStoreAPI },
} = courseStoreAPI;
