import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import CookieMonster from "api/CookieMonster";
import { BackendDataShape, UsedAPI } from "api/config";
import { convertToEncodedURL } from "assets/usefulFunctions";
import { APaginationEntity, BackendDataPageShape } from "types";
import { APersonelFileObj } from "types/endpoints/personelFile";
import { BackendPaginationRequestObject } from "types/endpoints/personel";

export const personelFileStoreAPI = createApi({
  reducerPath: "personelFileStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/personel/files`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["PersonelFileList"],
  endpoints: (builder) => {
    return {
      getPersonelFilePagination: builder.query<
        APaginationEntity<APersonelFileObj[]>,
        BackendPaginationRequestObject<Partial<APersonelFileObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataPageShape<APersonelFileObj[]>,
        ) => {
          const { meta, entities } = response.data;
          const dataWithChip = entities.map((val) => {
            return {
              ...val,
            };
          });
          return { meta, entities: dataWithChip };
        },
        providesTags: ["PersonelFileList"],
      }),
      getPersonelFileById: builder.query<
        APersonelFileObj,
        Partial<APersonelFileObj>
      >({
        query: ({ id, ...rest }) => {
          let params = "";
          if (rest) {
            params = convertToEncodedURL(rest);
            params = params.length ? "?" + params : "";
          }
          let url = `/${id}` + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<APersonelFileObj>) =>
          response.data,
        providesTags: ["PersonelFileList"],
      }),
      createPersonelFile: builder.mutation<
        BackendDataShape<APersonelFileObj>,
        { file: File | undefined }
      >({
        query: ({ file }) => {
          const formData = new FormData();
          file && formData.append("file", file);
          return {
            url: ``,
            body: formData,
            method: "POST",
          };
        },
        invalidatesTags: ["PersonelFileList"],
      }),
      deletePersonelFile: builder.mutation<
        BackendDataShape<string>,
        { idPersonelFile: number }
      >({
        query: ({ idPersonelFile }) => {
          return {
            url: `/${idPersonelFile}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["PersonelFileList"],
      }),
    };
  },
});
export const {
  useGetPersonelFilePaginationQuery,
  useGetPersonelFileByIdQuery,
  useCreatePersonelFileMutation,
  useDeletePersonelFileMutation,
  util: { resetApiState: resetPersonelFileStoreAPI },
} = personelFileStoreAPI;
