import { BackendDataShape } from "api/config";

import { convertToEncodedURL, jsonToFormData } from "assets/usefulFunctions";

import {
  AMasterDataProfession,
  AMasterDataQualification,
} from "types/endpoints/masterData/masterData";

import { masterDataStoreAPI } from "../masterDataStoreAPI";

export const masterDataProfessionStoreAPI = masterDataStoreAPI.injectEndpoints({
  endpoints: (builder) => {
    return {
      getProfessionList: builder.query({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/profesi" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<AMasterDataProfession[]>,
        ) => response.data,
        providesTags: ["ProfessionList"],
      }),
      createProfession: builder.mutation<
        BackendDataShape<object>,
        Partial<AMasterDataProfession>
      >({
        query: (obj) => {
          return {
            url: "/profesi",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["ProfessionList"],
      }),
      updateProfession: builder.mutation<
        BackendDataShape<object>,
        Partial<AMasterDataProfession>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: "/profesi/" + id,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["ProfessionList"],
      }),
      deleteProfession: builder.mutation<
        BackendDataShape<object>,
        { id: number }
      >({
        query: ({ id }) => {
          return {
            url: `/profesi/` + id,
            method: "DELETE",
          };
        },
        invalidatesTags: ["ProfessionList"],
      }),
      getQualificationProfessionList: builder.query({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/profesi/qualification" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<AMasterDataQualification[]>,
        ) => response.data,
        providesTags: ["QualificationList"],
      }),
      createQualification: builder.mutation<
        BackendDataShape<object>,
        Partial<AMasterDataQualification>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/profesi/${id}/qualification`,
            body: rest,
            method: "POST",
          };
        },
        invalidatesTags: ["QualificationList"],
      }),
      updateQualification: builder.mutation<
        BackendDataShape<object>,
        { id: number; qualificationId: number }
      >({
        query: ({ id, qualificationId, ...rest }) => {
          return {
            url: `/profesi/${id}/qualification/${qualificationId}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["QualificationList"],
      }),
      deleteQualification: builder.mutation<
        BackendDataShape<object>,
        { id: number; qualificationId: number }
      >({
        query: ({ id, qualificationId }) => {
          return {
            url: `/profesi/${id}/qualification/${qualificationId}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["QualificationList"],
      }),

      restoreQualification: builder.mutation<
        BackendDataShape<object>,
        { file: File }
      >({
        query: ({ ...rest }) => {
          return {
            url: "/profesi/restore",
            body: jsonToFormData(rest),
            method: "POST",
          };
        },
        invalidatesTags: ["QualificationList"],
      }),
    };
  },
});

export const {
  useGetProfessionListQuery,
  useCreateProfessionMutation,
  useUpdateProfessionMutation,
  useDeleteProfessionMutation,
  useCreateQualificationMutation,
  useDeleteQualificationMutation,
  useUpdateQualificationMutation,
  useGetQualificationProfessionListQuery,
  useRestoreQualificationMutation,
  util: { resetApiState: resetMasterDataProfessionAPI },
} = masterDataProfessionStoreAPI;
