import React from "react";
import { useLocation } from "react-router-dom";
import { KiddoElement } from "components/Sidebar/_components/SidebarItem";

interface Props {
  kiddos?: KiddoElement[];
  parentURL?: string;
}

interface ReturnItem {
  /**
   * if the NavbarItem match, either the parent or the child
   */
  match: boolean;
  /**
   * if the NavbarItem a parent, this will show which kiddo is matched.
   * if there is no kiddos, this will be empty string
   */
  matchKiddo: string;
}
/**
 * - If there is no `kiddos`, pathname should includes parentURL
 * - If there is `kiddos`, if the `kiddos` match the `pathname`, the `match` should be true
 *
 * On assumption the kiddo should contain the whole link relative to root.
 */
const useMatchKiddo = ({ kiddos, parentURL }: Props): ReturnItem => {
  const { pathname } = useLocation();
  // const [state, setState] = React.useState<ReturnItem>({match: false, matchKiddo: ""});

  return React.useMemo<ReturnItem>(() => {
    if (kiddos) {
      const found = kiddos.find((kid) => {
        return kid.kiddoURL ? pathname.indexOf(kid.kiddoURL) === 0 : false;
      });

      return {
        match: !!found,
        matchKiddo: found?.kiddoURL ?? "",
      };
    } else {
      return {
        match: parentURL ? pathname.indexOf(parentURL) === 0 : false, //
        matchKiddo: "",
      };
    }

    // let arr = pathname.split("/");
    // let foundIndex = arr.findIndex((val) => {
    //   let parentFindURL = parentURL?.split("/").slice(-1)[0];
    //   return parentFindURL === `${val}`;
    // });

    // console.log({ parentURL, pathname, arr, foundIndex });
    // if (foundIndex === -1) return defaultVal;
    // if (kiddos && kiddos.length) {
    //   let parent = arr[foundIndex];
    //   let nextInArr = arr[foundIndex + 1];
    //   if (nextInArr) {
    //     let found = kiddos.find(({ kiddoURL }) => {
    //       const match = `/${parent}/${nextInArr}`;

    //       const splitedKiddoURL = kiddoURL
    //         ?.split("/")
    //         .slice(3, kiddoURL.split("/").length);
    //       const resSplitedKiddoURL = `/${splitedKiddoURL?.join("/")}`;
    //       // console.log({ resSplitedKiddoURL, match });
    //       return resSplitedKiddoURL === match;
    //     });

    //     return found
    //       ? {
    //           match: true,
    //           matchKiddo: found.kiddoURL!,
    //         }
    //       : defaultVal;
    //   } else {
    //     return defaultVal;
    //   }
    // } else {
    //   return { match: true, matchKiddo: "" };
    // }
  }, [kiddos, parentURL, pathname]);

  // return state
};

export default useMatchKiddo;
