import React from "react";
import { Navigate } from "react-router-dom";
import {
  KnownUsertype,
  KnownUsertypeName,
} from "../api/credentialsAPI/usertype";
import { checkIfUndefined } from "../assets/usefulFunctions";
import useUsertype, { useUsertypeName } from "../hooks/auth/useUsertype";
import useGetSisfoParentUrl from "../hooks/useGetSisfoParentUrl";

interface Props {}

const Redirector: React.FunctionComponent<Props> = (props: Props) => {
  const usertypeName = useUsertypeName();
  const to = getDefaultRoute(usertypeName);
  const sisfo = useGetSisfoParentUrl();

  if (checkIfUndefined(usertypeName)) {
    return <>Loading...</>;
  }
  return <Navigate replace to={`/${sisfo}${to}`} />;
};

export default Redirector;

export const getDefaultRoute = (usertypeLogin?: KnownUsertypeName) => {
  switch (usertypeLogin) {
    case "personel":
      return "/profile";
    case "superadmin":
      /**
       * @todo update this for supporting different sisfo later
       */
      return "/management/superadmin/account";
    case "kasubsiJasrek":
    case "kasiWatpers":
    case "kasiBintal":
    case "kasiKum":
    case "kasubsiEvadal":
    case "kasiProfesi":
    case "kasubditWatpers":
      return "/dashboard/hukum";
    case "kasubsiUrikes":
    case "kasiKesud":
    case "kasiMinkes":
    case "kasubditKes":
      return "/dashboard/kesehatan";
    // case "admin":
    // case "kasubdit":
    case "kasubsiDalpersmil":
    case "kasiDalpersmil":
      /**
       * @todo refactor when the presentation 4 April 2023 done
       */
      return "/dashboard/overview";
    case "kasiDalperssip":
    case "kasubsiDatapers":
    case "kasiMindik":
    case "kasubditMinpers":
    default:
      return "/dashboard/personel";
    /**
     * @todo butuh penyesuaian default route untuk semua user
     */
  }
};

export const useDefaultRoute = () => {
  const usertypeName = useUsertypeName();
  return React.useMemo(() => {
    return getDefaultRoute(usertypeName);
  }, [usertypeName]);
};
