import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "..";
import CookieMonster from "../../api/CookieMonster";
import {
  letUserLogin,
  LoginObj,
  UserDataWOToken,
} from "../../api/credentialsAPI";
import { ListOfRoutes } from "../../config/nav";

import { removeUserData } from "../../store/userStore";

import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { BackendDataShape, CredsLinks } from "../../api/config";

import axios from "../../api";
import useGetSisfoParentUrl from "../useGetSisfoParentUrl";
import { resetPersonelStoreAPI } from "../../store/sisfopers/personelStore/personelStoreAPI";
import { resetUserStoreAPI } from "../../store/userStore/userStoreAPI";
import { resetUsertypeStoreAPI } from "../../store/usertypeStore/usertypeStoreAPI";
import { resetApprovalsStoreAPI } from "../../store/sisfopers/personelStore/personelApprovalAPI";
import { resetLovStoreAPI } from "../../store/sisfopers/lovStore/lovStoreAPI";
import { resetAdditionalTypeQuery } from "../../store/sisfopers/additionaltypeStore/additionaltypeStoreAPI";
import { resetCaseStoreAPI } from "../../store/sisfopers/caseStore/caseStoreAPI";
import { resetMasterDataStoreAPI } from "../../store/sisfopers/masterDataStore/masterDataStoreAPI";
import { resetRecommendPositionStoreAPI } from "../../store/sisfopers/recommendPositionStore/recommendPositionStoreAPI";
import { resetDashboardStoreAPI } from "../../store/sisfopers/dashboardStore/dashboardStoreAPI";
import { resetMasterDataEducationAPI } from "../../store/sisfopers/masterDataStore/education/masterDataEducationStoreAPI";
import { resetMasterDataKorpsAPI } from "../../store/sisfopers/masterDataStore/korps/masterDataKorpsStoreAPI";
import { resetMasterDataProfessionAPI } from "../../store/sisfopers/masterDataStore/profession/masterDataProfessionStoreAPI";
import { resetMasterDataRankAPI } from "../../store/sisfopers/masterDataStore/rank/masterDataRankStoreAPI";
import { resetMasterDataSkillAPI } from "../../store/sisfopers/masterDataStore/skill/masterDataSkillStoreAPI";
import { resetMasterDataWorkUnitAPI } from "../../store/sisfopers/masterDataStore/workunit/masterDataWorkUnitStoreAPI";
import { resetEducationEqualityStoreAPI } from "../../store/sisfopers/masterDataStore/education/educationEqualityStore";
import { resetAssetStoreAPI } from "../../store/sisfopers/assetStore/assetStoreAPI";

export const useClearAllCache = () => {
  const dispatch = useAppDispatch();

  return () => {
    // Tambahkan secara abjad
    dispatch(resetAssetStoreAPI());
    dispatch(resetAdditionalTypeQuery());
    dispatch(resetApprovalsStoreAPI());
    dispatch(resetLovStoreAPI());
    dispatch(resetMasterDataStoreAPI());
    dispatch(resetMasterDataEducationAPI());
    dispatch(resetMasterDataKorpsAPI());
    dispatch(resetMasterDataProfessionAPI());
    dispatch(resetMasterDataRankAPI());
    dispatch(resetMasterDataSkillAPI());
    dispatch(resetMasterDataWorkUnitAPI());
    dispatch(resetPersonelStoreAPI());
    dispatch(resetUserStoreAPI());
    dispatch(resetUsertypeStoreAPI());
    dispatch(resetCaseStoreAPI());
    dispatch(resetRecommendPositionStoreAPI());
    dispatch(resetDashboardStoreAPI());
    dispatch(resetEducationEqualityStoreAPI());
  };
};

/**
 *
 * @param shouldNavigate in real case, we want to navigate the screen i.e. `true` on `logout`. but in testing condition, we do not need this i.e. `false`
 * @returns a logout function
 */
export const useLogout = (shouldNavigate = true) => {
  const clearAllCache = useClearAllCache();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sisfoParentUrl = useGetSisfoParentUrl();

  return () => {
    // Samakan cooke yg diremove dengan cookie yg diSave
    CookieMonster.removeAllTheseCookies([
      "jwt",
      "id",
      "username",
      "jwt_change_password",
      "usertypeId",
      "userDetailId",
      "usertypeRole",
      "usertypeName",
    ]);
    clearAllCache();
    dispatch(removeUserData());
    shouldNavigate && navigate(ListOfRoutes.loginpage + "/" + sisfoParentUrl);
    localStorage.clear();
  };
};

export interface GetUserDataReqObj {
  id?: number;
  user_id?: number;
  username?: string;
  email?: string;
}

export function getUser(obj: GetUserDataReqObj) {
  let params = "";
  if (obj) {
    params = convertToEncodedURL(obj);
    params = params.length ? "?" + params : "";
  }

  const url = `${CredsLinks.user(params)}`;

  return axios.get<BackendDataShape<UserDataWOToken[]>>(url, {
    data: obj,
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  });
}

export const useLogin = () => {
  return async (obj?: LoginObj) => {
    const {
      data: { data },
    } = await letUserLogin(obj);
    const { jwt, exp, iat, ...rest } = data;

    // Samakan cooke yg diremove dengan cookie yg diSave
    CookieMonster.saveAllTheseCookies(
      {
        jwt,
        id: rest.id,
        username: rest.username,
        // usertype: rest.usertype,
        usertypeId: rest.usertypeId,
        userDetailId: rest.userDetailId ?? undefined,
        usertypeRole: rest.usertypeRole,
        usertypeName: rest.usertypeName,
      },
      { expires: new Date(exp * 1000) },
    );
    return rest;
  };
};
