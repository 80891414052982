import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BackendDataShape, UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

import { convertToEncodedURL, jsonToFormData } from "assets/usefulFunctions";

import { APaginationEntity, BackendDataPageShape } from "types";
import {
  APosition,
  APositionGetObj,
  APositionReqObj,
} from "types/endpoints/masterData/masterData";
import { BackendPaginationRequestObject } from "types/endpoints/personel";
import { AEligiblePosRequestObject } from "types/endpoints/personel/dalpers";
import { APositionList } from "types/endpoints/position";

export const masterDataStoreAPI = createApi({
  reducerPath: "masterDataStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: [
    "RankList",
    "PositionList",
    "PositionDuplicateList",
    "WorkUnitList",
    "KorpsList",
    "RankRequirementList",
    "EducationList",
    "EducationEqualityList",
    "KorpsDetailList",
    "ProfessionList",
    "SkillList",
    "QualificationList",
  ],
  endpoints: (builder) => {
    return {
      getPositionList: builder.query<
        APaginationEntity<APosition[]>,
        BackendPaginationRequestObject<Partial<APositionGetObj>>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/jabatan" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<APosition[]>) =>
          response.data,
        providesTags: ["PositionList"],
      }),
      getPositionDuplicateList: builder.query<
        APaginationEntity<APositionList[]>,
        BackendPaginationRequestObject<AEligiblePosRequestObject>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/jabatan/duplicate" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataPageShape<APositionList[]>) =>
          response.data,
        providesTags: ["PositionDuplicateList"],
      }),
      createPosition: builder.mutation<
        BackendDataShape<object>,
        Partial<APositionReqObj>
      >({
        query: (obj) => {
          return {
            url: "/jabatan",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["PositionList"],
      }),
      updatePosition: builder.mutation<
        BackendDataShape<object>,
        Partial<APositionReqObj & { id: number }>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: "/jabatan/" + id,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["PositionList"],
      }),
      deletePosition: builder.mutation<
        BackendDataShape<object>,
        { id: number }
      >({
        query: ({ id }) => {
          return {
            url: `/jabatan/` + id,
            method: "DELETE",
          };
        },
        invalidatesTags: ["PositionList"],
      }),
      restorePosition: builder.mutation<
        BackendDataShape<object>,
        { file: File }
      >({
        query: ({ ...rest }) => {
          return {
            url: "/jabatan/restore",
            body: jsonToFormData(rest),
            method: "POST",
          };
        },
        invalidatesTags: ["PositionList"],
      }),
    };
  },
});

export const {
  useGetPositionListQuery,
  useGetPositionDuplicateListQuery,
  useCreatePositionMutation,
  useUpdatePositionMutation,
  useDeletePositionMutation,
  useRestorePositionMutation,
  util: { resetApiState: resetMasterDataStoreAPI },
} = masterDataStoreAPI;
