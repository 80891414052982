import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { UsedAPI } from "api/config";
import CookieMonster from "api/CookieMonster";

export const mainStoreAPI = createApi({
  reducerPath: "mainStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers, { endpoint }) => {
      const excludedEndpoints = ["postResetPassword", "postVerifyEmail"];

      if (!excludedEndpoints.includes(endpoint)) {
        const token = CookieMonster.loadCookie("jwt");
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  tagTypes: [
    "Urikes_Data_CSV",
    "Urikes_Intensif",
    "Urikes_Tempur",
    "Urikes_Matra",
  ],
  endpoints: () => ({}),
});

export const {
  util: { resetApiState: resetMainStoreAPI },
} = mainStoreAPI;
