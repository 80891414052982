import { BackendDataShape } from "api/config";

import { convertToEncodedURL, jsonToFormData } from "assets/usefulFunctions";

import { RequireSome } from "types";
import {
  ARank,
  ARankRequirement,
  ARankRequirementReqObj,
} from "types/endpoints/masterData/masterData";

import { masterDataStoreAPI } from "../masterDataStoreAPI";

export const masterDataRankStoreAPI = masterDataStoreAPI.injectEndpoints({
  endpoints: (builder) => {
    return {
      getRankList: builder.query({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/pangkat" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ARank[]>) =>
          response.data,
        providesTags: ["RankList"],
      }),
      getRankRequirementList: builder.query<
        ARankRequirement[],
        {
          idRank: number;
        }
      >({
        query: (obj) => {
          let url = `/pangkat/${obj.idRank}/pangkat_eligible`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ARankRequirement[]>) =>
          response.data,
        providesTags: (_, __, c) => [
          "RankRequirementList",
          { id: c.idRank, type: "RankRequirementList" },
        ],
      }),
      getDetailRankRequirement: builder.query<
        ARankRequirement,
        {
          idRank: number;
          idEligible: number;
        }
      >({
        query: (obj) => {
          let url = `/pangkat/${obj.idRank}/pangkat_eligible/${obj.idEligible}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ARankRequirement>) =>
          response.data,
        providesTags: ["RankRequirementList"],
      }),
      createRank: builder.mutation<BackendDataShape<object>, Partial<ARank>>({
        query: (obj) => {
          return {
            url: "/pangkat",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["RankList"],
      }),
      createRankRequirement: builder.mutation<
        BackendDataShape<object>,
        Partial<ARankRequirementReqObj> & { idRank: number }
      >({
        query: ({ idRank, ...rest }) => {
          return {
            url: `/pangkat/${idRank}/pangkat_eligible`,
            body: rest,
            method: "POST",
          };
        },
        invalidatesTags: ["RankRequirementList"],
      }),
      updateRank: builder.mutation<BackendDataShape<object>, Partial<ARank>>({
        query: ({ id, ...rest }) => {
          return {
            url: "/pangkat/" + id,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["RankList"],
      }),
      updateRankRequirement: builder.mutation<
        BackendDataShape<object>,
        Partial<ARank> & { idEligible: number }
      >({
        query: ({ id, idEligible, ...rest }) => {
          return {
            url: `/pangkat/${id}/pangkat_eligible/${idEligible}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["RankRequirementList"],
      }),
      updateRankMddp: builder.mutation<
        BackendDataShape<object>,
        RequireSome<ARank, "id" | "mddp">
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/pangkat/${id}/mddp`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["RankList"],
      }),
      deleteRank: builder.mutation<BackendDataShape<object>, { id: number }>({
        query: ({ id }) => {
          return {
            url: `/pangkat/` + id,
            method: "DELETE",
          };
        },
        invalidatesTags: ["RankList"],
      }),
      deleteRankRequirement: builder.mutation<
        BackendDataShape<object>,
        { id: number; idEligible: number }
      >({
        query: ({ id, idEligible }) => {
          return {
            url: `/pangkat/${id}/pangkat_eligible/${idEligible}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["RankRequirementList"],
      }),
      restoreRank: builder.mutation<BackendDataShape<object>, { file: File }>({
        query: ({ ...rest }) => {
          return {
            url: "/pangkat/restore",
            body: jsonToFormData(rest),
            method: "POST",
          };
        },
        invalidatesTags: ["RankList"],
      }),
    };
  },
});

export const {
  useGetRankListQuery,
  useGetDetailRankRequirementQuery,
  useGetRankRequirementListQuery,
  useCreateRankMutation,
  useCreateRankRequirementMutation,
  useUpdateRankMutation,
  useUpdateRankRequirementMutation,
  useUpdateRankMddpMutation,
  useDeleteRankMutation,
  useDeleteRankRequirementMutation,
  useRestoreRankMutation,
  util: { resetApiState: resetMasterDataRankAPI },
} = masterDataRankStoreAPI;
