import React, { ReactNode } from "react";
import { toast } from "react-toastify";
import useUsertype, { useUsertypeName } from "../../../hooks/auth/useUsertype";
import NoMatch from "../../../pages/NoMatch";
import StyledToastBodyPuspenerbal from "../../Toast/StyledToastBodyPuspenerbal";
import {
  ADashboardPermission,
  AManagementPermission,
  AOtherPermission,
} from "../../../api/permissionAPI";
import {
  KnownUsertype,
  KnownUsertypeName,
} from "../../../api/credentialsAPI/usertype";

interface Props {
  /**
   * any allowed usertype or usertypename. if length is 0, any user is allowed
   */
  allowedUsers: (KnownUsertype | KnownUsertypeName)[];
  /**
   * @deprecated currently we do not use screenType
   */
  screenType?: ScreenType;
  children?: ReactNode;
}

type ScreenType =
  | {
      type: "dashboard";
      screenName: keyof ADashboardPermission;
    }
  | {
      type: "management";
      screenName: keyof AManagementPermission;
    }
  | {
      type: "other";
      screenName: keyof AOtherPermission;
    };

// type ScreenType<D extends "dashboard" | "management" | "other" = "dashboard"> = D extends "dashboard" ? {
//   type: D,
//   screenName: keyof ADashboardPermission;
// } : D extends "management" ? {
//   type: D,
//   screenName: keyof AManagementPermission;
// }: D extends "other" ? {
//   type: D,
//   screenName: keyof AOtherPermission
// } : never

/**
 *  We assume that the jwt was checked before.
 */
const UserGate: React.FunctionComponent<Props> = ({
  children,
  allowedUsers,
}) => {
  const usertype = useUsertype();
  const usertypeName = useUsertypeName();

  if (allowedUsers && allowedUsers.length) {
    if (usertype || usertypeName) {
      let userIsAllowed = allowedUsers.some(
        (user) => usertype === user || usertypeName === user,
      );

      try {
        if (userIsAllowed) return <>{children}</>;
        throw new Error("not permitted");
      } catch (e) {
        toast.error(
          <StyledToastBodyPuspenerbal
            headerText="Error!"
            description="Anda tidak memiliki akses ke halaman ini."
          />,
        );
        return <NoMatch />;
      }
    } else {
      toast.error(
        <StyledToastBodyPuspenerbal
          headerText="Error!"
          description="Anda tidak memiliki akses ke halaman ini."
        />,
      );
      return <NoMatch />;
    }
  } else {
    return <>{children}</>;
  }
};

export default UserGate;
