import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../../api/config";
import CookieMonster from "../../../api/CookieMonster";
import { convertToEncodedURL } from "../../../assets/usefulFunctions";

/**
 * @todo kelompokkan tipe2 ini berdasarkan kegunaan
 */
export type ALovTypes =
  | ALOVPersonel
  | ALOVPendidikan
  | ALOVPangkat
  | ALOVKasusKum
  | ALOVKesehatan
  | ALOVKeluarga
  | ALOVProfesi
  | "keahlian"
  | "jabatan"
  | "satker"
  | "kategoriPendidikan"
  | "korps"
  | "statusTidakAktif"
  | "kejuruanTypeKejuruan"
  | "kejuruanTypeKorps"
  | "kejuruanType"
  | "periodeNaikPangkat";
export type ALOVPersonel = "jeniskelaminpersonel" | "korps";
export type ALOVPendidikan =
  | "jenispendidikan"
  | "tingkatkeahlian"
  | "tingkatpenggunaan"
  | "jenisPendidikanKesetaraan";
export type ALOVPangkat = "kategoriUmumPangkat" | "kategoriPangkat" | "pangkat";
export type ALOVKasusKum =
  | "statusPerkembanganKasus"
  | "statusKasus"
  | "jenisKasus";
export type ALOVKesehatan = "kategoristakes" | "semesterstakes";
export type ALOVProfesi = "profesi" | "profesipenerbang";
export type ALOVKeluarga = "jeniskelaminkk" | "statushubungankk";

export const lovStoreAPI = createApi({
  reducerPath: "lovStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/lov`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["LovList"],
  endpoints: (builder) => {
    return {
      getLovs: builder.query<any[], { cat: ALovTypes }>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<string[]>) =>
          response.data,
        providesTags: ["LovList"],
      }),
    };
  },
});

export const {
  useGetLovsQuery,
  util: { resetApiState: resetLovStoreAPI },
} = lovStoreAPI;
