import { BackendDataShape } from "api/config";

import { convertToEncodedURL, jsonToFormData } from "assets/usefulFunctions";

import {
  AKorps,
  AKorpsDetail,
  AKorpsDetailReqObj,
  AKorpsReqObj,
} from "types/endpoints/masterData/masterData";

import { masterDataStoreAPI } from "../masterDataStoreAPI";

export const masterDataKorpsStoreAPI = masterDataStoreAPI.injectEndpoints({
  endpoints: (builder) => {
    return {
      getKorpsList: builder.query({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/korps" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AKorps[]>) =>
          response.data,
        providesTags: ["KorpsList"],
      }),
      getKorpsDetailsList: builder.query<AKorpsDetail[], { idKorps: number }>({
        query: ({ idKorps }) => {
          let url = `/korps/${idKorps}/korps_detail`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AKorpsDetail[]>) =>
          response.data,
        providesTags: ["KorpsDetailList"],
      }),
      createKorps: builder.mutation<
        BackendDataShape<object>,
        Partial<AKorpsReqObj>
      >({
        query: (obj) => {
          return {
            url: "/korps",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["KorpsList"],
      }),
      createKorpsDetails: builder.mutation<
        BackendDataShape<object>,
        Partial<AKorpsDetailReqObj> & { idKorps: number }
      >({
        query: ({ idKorps, ...rest }) => {
          return {
            url: `/korps/${idKorps}/korps_detail`,
            body: rest,
            method: "POST",
          };
        },
        invalidatesTags: ["KorpsDetailList"],
      }),
      updateKorps: builder.mutation<
        BackendDataShape<object>,
        Partial<AKorpsReqObj> & { id: number }
      >({
        query: ({ id, ...rest }) => {
          return {
            url: "/korps/" + id,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["KorpsList"],
      }),
      updateKorpsDetail: builder.mutation<
        BackendDataShape<object>,
        Partial<AKorpsDetailReqObj> & { idKorps: number }
      >({
        query: ({ idKorps, id, ...rest }) => {
          return {
            url: `/korps/${idKorps}/korps_detail/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["KorpsDetailList"],
      }),
      deleteKorps: builder.mutation<BackendDataShape<object>, { id: number }>({
        query: ({ id }) => {
          return {
            url: `/korps/` + id,
            method: "DELETE",
          };
        },
        invalidatesTags: ["KorpsList"],
      }),
      deleteKorpsDetails: builder.mutation<
        BackendDataShape<object>,
        { id: number; idKorpsDetail: number }
      >({
        query: ({ id, idKorpsDetail }) => {
          return {
            url: `/korps/${id}/korps_detail/${idKorpsDetail}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["KorpsDetailList"],
      }),

      restoreKorps: builder.mutation<BackendDataShape<object>, { file: File }>({
        query: ({ ...rest }) => {
          return {
            url: "/korps/restore",
            body: jsonToFormData(rest),
            method: "POST",
          };
        },
        invalidatesTags: ["KorpsDetailList"],
      }),
    };
  },
});

export const {
  useGetKorpsDetailsListQuery,
  useGetKorpsListQuery,
  useCreateKorpsDetailsMutation,
  useCreateKorpsMutation,
  useUpdateKorpsDetailMutation,
  useUpdateKorpsMutation,
  useDeleteKorpsDetailsMutation,
  useDeleteKorpsMutation,
  useRestoreKorpsMutation,
  util: { resetApiState: resetMasterDataKorpsAPI },
} = masterDataKorpsStoreAPI;
